var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "c-table",
        {
          attrs: {
            title: "발의 전 부서장 검토항목",
            columns: _vm.grid.columns,
            gridHeight: _vm.gridHeight,
            data: _vm.moc.reviews,
            filtering: false,
            usePaging: false,
            columnSetting: false,
            editable: _vm.editable && !_vm.disabled,
          },
          on: { "table-data-change": _vm.tableDataChange },
        },
        [
          _c(
            "template",
            { slot: "table-button" },
            [
              _c(
                "q-btn-group",
                { attrs: { outline: "" } },
                [
                  _vm.editable && !_vm.disabled
                    ? _c("c-btn", {
                        attrs: {
                          isSubmit: _vm.isSave,
                          url: _vm.saveUrl,
                          param: _vm.grid.saveData,
                          mappingType: "PUT",
                          label: "저장",
                          icon: "save",
                        },
                        on: {
                          beforeAction: _vm.saveReview,
                          btnCallback: _vm.saveReviewCallback,
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }